export const Roadmap = (props) => {
    return (
      <div id='roadmap' className='text-center'>
        <div className='container'>
          <div className='roadmap-title'>
            <h2>The Roadmap</h2>
            <p>
              Spooky is an nft project in ergo blockchain. Our main purpose is to build games and provide special perks to our nft holders.
            </p>
          </div>
          <div className='row'>
            {props.data
              ? props.data.map((d, i) => (
                  <div key={`${d.name}-${i}`} className='col-md-4'>
                    {' '}
                    <i className={d.icon}></i>
                    <div className='roadmap-desc'>
                      <h3>{d.name}</h3>
                      <p>{d.text}</p>
                    </div>
                  </div>
                ))
              : 'loading'}
          </div>
        </div>
      </div>
    )
  }
  